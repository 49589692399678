header#masthead {
	position: relative;
	
	.navbar {
		z-index: $zindex-navbar;
		
		.dropdown-menu {
			transition: $transition-base;
		}
		
		.dropdown:hover > .dropdown-menu {
			display: block;
		}
		
		.active {
			.nav-link, .dropown-item {
				font-weight: bold;
				color: $dark;
			}
		}
		
		.icon-bar {
			width: 22px;
			height: 2px;
			background-color: $black;
			display: block;
			transition: all 0.2s;
			margin-top: 4px
		}
		
		.navbar-toggler {
			border: none;
			background: transparent !important;
			
			&:hover, &:focus, &:active {
				outline: none;
				box-shadow: none;
			}
		}
		
		.navbar-toggler .top-bar {
			transform: rotate(45deg);
			transform-origin: 10% 10%;
			margin-top: 0;
		}
		
		.navbar-toggler .middle-bar {
			opacity: 0;
			width: 15px;
		}
		
		.navbar-toggler .bottom-bar {
			transform: rotate(-45deg);
			transform-origin: 10% 90%;
		}
		
		.navbar-toggler.collapsed .top-bar {
			transform: rotate(0);
		}
		
		.navbar-toggler.collapsed .middle-bar {
			opacity: 1;
		}
		
		.navbar-toggler.collapsed .bottom-bar {
			transform: rotate(0);
		}
	}
}
