@font-face {
	font-family: "dieeisdielerei Icons";
	src: url($base-font-path + "dieeisdielerei-icons/dieeisdielerei-icons.eot");
	src: url($base-font-path + "dieeisdielerei-icons/dieeisdielerei-icons.eot#iefix")
			format("embedded-opentype"),
		url($base-font-path + "dieeisdielerei-icons/dieeisdielerei-icons.woff2")
			format("woff2"),
		url($base-font-path + "dieeisdielerei-icons/dieeisdielerei-icons.woff") format("woff"),
		url($base-font-path + "dieeisdielerei-icons/dieeisdielerei-icons.ttf")
			format("truetype"),
		url($base-font-path + "dieeisdielerei-icons/dieeisdielerei-icons.svg#dieeisdielerei-icons")
			format("svg");
	font-weight: normal;
	font-style: normal;
}

[class^="dieeisdielerei-icons-"]:before,
[class*=" dieeisdielerei-icons-"]:before {
	font-family: "dieeisdielerei Icons", sans-serif;
	font-style: normal;
	font-weight: normal;
	speak: never;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: 0.2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: 0.2em;

	//font-size: 120%;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

.dieeisdielerei-icons-chevron-down:before {
	content: "\e800";
}

.dieeisdielerei-icons-chevron-left:before {
	content: "\e801";
}

.dieeisdielerei-icons-chevron-right:before {
	content: "\e802";
}

.dieeisdielerei-icons-chevron-up:before {
	content: "\e803";
}

.dieeisdielerei-icons-cart3:before {
	content: "\e804";
}

.dieeisdielerei-icons-search:before {
	content: "\e805";
}

.dieeisdielerei-icons-check-lg:before {
	content: "\e806";
}

.dieeisdielerei-icons-x-circle:before {
	content: "\e80a";
}

.dieeisdielerei-icons-x-lg:before {
	content: "\e80b";
}

.dieeisdielerei-icons-x-1:before {
	content: "\e80c";
}

.dieeisdielerei-icons-facebook:before {
	content: "\e80f";
}

.dieeisdielerei-icons-instagram:before {
	content: "\e810";
}

.dieeisdielerei-icons-envelope-fill:before {
	content: "\e811";
}

.dieeisdielerei-icons-envelope:before {
	content: "\e812";
}

.dieeisdielerei-icons-whatsapp:before {
	content: "\e813";
}

.dieeisdielerei-icons-twitter:before {
	content: "\e814";
}

.dieeisdielerei-icons-telegram:before {
	content: "\e815";
}

.dieeisdielerei-icons-phone:before {
	content: "\e816";
}

.dieeisdielerei-icons-telephone-fill:before {
	content: "\e817";
}

.dieeisdielerei-icons-telephone:before {
	content: "\e818";
}

.dieeisdielerei-icons-caret-down-fill:before {
	content: "\e819";
}

.dieeisdielerei-icons-caret-down:before {
	content: "\e81a";
}

.dieeisdielerei-icons-caret-left-fill:before {
	content: "\e81b";
}

.dieeisdielerei-icons-caret-left:before {
	content: "\e81c";
}

.dieeisdielerei-icons-caret-right-fill:before {
	content: "\e81d";
}

.dieeisdielerei-icons-caret-right:before {
	content: "\e81e";
}

.dieeisdielerei-icons-caret-up-fill:before {
	content: "\e81f";
}

.dieeisdielerei-icons-at:before {
	content: "\e821";
}

.dieeisdielerei-icons-home-icon:before {
	content: "\e822";
}

.dieeisdielerei-icons-dash-circle:before {
	content: "\e823";
}

.dieeisdielerei-icons-plus-circle:before {
	content: "\e824";
}

.dieeisdielerei-icons-plus:before {
	content: "\e825";
}

.dieeisdielerei-icons-x:before {
	content: "\e826";
}

.dieeisdielerei-icons-linkedin:before {
	content: "\e827";
}

.dieeisdielerei-icons-youtube:before {
	content: "\e828";
}

.dieeisdielerei-icons-xing-squared:before {
	content: "\f169";
}

.dieeisdielerei-icons-geo-alt-fill:before {
	content: "\e829";
}

.dieeisdielerei-icons-person-circle:before {
	content: "\e82a";
}
