* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::selection {
	background: $primary;
	color: #ffffff;
}

body {
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
	position: relative;
}

a {
	transition: $transition-base;

	&:hover,
	&:focus {
		transition: $transition-base;
		text-decoration: none;
	}
}

figure {
	margin: 0;
}

a:focus {
	outline: 0;
}

a.no-style,
a.img-link {
	color: inherit !important;
	text-decoration: none !important;
	border: none !important;

	&:hover,
	&:focus {
		color: inherit !important;
		text-decoration: none !important;
	}

	&:after {
		content: none;
	}
}

.overlay {
	position: relative;

	&:after {
		content: "";
		position: absolute;
		z-index: $zindex-overlay;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.img-full {
	width: 100%;
	height: auto;
}

.background-full,
.bg-full {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: initial;
}

.top {
	top: 0;
}

.bottom {
	bottom: 0;
}

.left {
	left: 0;
}

.right {
	right: 0;
}

.line-height-1 {
	line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	text-transform: uppercase;
	.text-white &,
	.text-light & {
		color: inherit;
	}
}

h1{
	&#dieeisdielerei-heading-h1{
		&:after{
			content:"";
			display:block;
			height:5px;
			width:70px;
			background: $primary;
			margin: 0.5rem auto 0;
		}
	}
}

h2 {
	&#dieeisdielerei-heading-h2{
		&:after{
			content:"";
			display:block;
			height:5px;
			width:70px;
			background: $primary;
			margin: 0.5rem auto 0;
		}
	}
}

.textbox :last-child,
.textwidget :last-child,
.wysiwyg :last-child {
	&:not(.form-group) {
		margin-bottom: 0;
	}
}

.hyphenate {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;

	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

.textbox img {
	@extend .img-fluid;
}

.aligncenter {
	@extend .text-center;
}

.alignleft {
	@extend .text-start;
}

.alignright {
	@extend .text-end;
}

.pagination {
	.page-item .current {
		background: $primary;
		color: $white;
		border-color: $primary;
	}
}

.btn-action {
	cursor: pointer;
	position: absolute;
	height: 60px;
	width: 60px;
	box-shadow: $box-shadow-sm;
	border-radius: 50%;
	transition: $transition-base;
	z-index: $zindex-overlay + 2;

	[class^="dieeisdielerei-icons-"] {
		cursor: pointer;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 1.5rem;
		font-weight: bold;

		&:before {
			transition: $transition-base;
		}
	}

	&:hover {
		cursor: pointer;
		box-shadow: $box-shadow-lg;
		transition: $transition-base;
	}
}

.invert-color{
	filter:invert(1);
}