.full-width-video-wrapper {
	position: relative;
	overflow: hidden;
	z-index: $zindex-overlay - 1;
	
	.full-width-video {
		position: absolute;
		z-index: $zindex-overlay - 1;
		top: 50%;
		left: 50%;
		width: auto;
		min-width: 100%;
		height: auto;
		min-height: 100%;
		transform: translate(-50%, -50%);
	}
}

.video-wrap {
	position: relative;
	margin-bottom: $paragraph-margin-bottom;
	
	.video-play {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: rgba($primary, .9);
		color: $white;
		
		&:hover {
			background: rgba($primary, 1);
		}
	}
}
