footer#colophon {
    ul{
        padding-left:0;
        li{
            list-style:none;
            a{
                color:$white;
            }
        }
    }
}
