// Grid section
.grid-section {
	// Rows
	.grid-row {
	}
	
	// Columns
	.grid-col {
		.element-wrap > div + div {
			margin-top: 1rem;
		}
		
		.streched-group {
			> div {
				flex: 1;
				display: flex;
				flex-direction: column;
			}
			
			.card-element, .card-element > .card {
				height: 100%;
			}
			
			.card-element > .card {
				display: flex;
				flex-direction: column;
			}
		}
		
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	
	// Image
	.image-element {
		.figure.has-link {
			img {
				transition: $transition-base;
				position: relative;
			}
			
			&:hover img {
				transition: $transition-base;
				box-shadow: $box-shadow;
			}
		}
		
		.wrap {
			position: relative;
		}
		
		figure {
			width: 100%;
			margin-bottom: 0;
		}
		
		img {
			transition: $transition-base;
		}
	}
	
	// Accordion
	.accordion-element {
		.accordion-header {
			margin-bottom: .5rem;
		}
		
		.accordion-heading {
			font-family: $font-family-base;
			font-weight: bold;
		}
		
		.accordion-button {
			&:after {
				background-size: 1rem;
			}
		}
		
		.accordion-item {
			margin-bottom: 1.5rem;
		}
		
		.accordion-button {
			font-weight: bold;
			text-transform: uppercase;
		}
	}
	
	// Card
	.card-element {
		min-height: 250px;
		
		@include media-breakpoint-up(md) {
			min-height: 150px;
		}
		
		.has-link {
			transition: $transition-base;
			position: relative;
			
			&:hover {
				transition: $transition-base;
				box-shadow: $box-shadow;
			}
		}
		
		.overlay {
			.textbox {
				z-index: $zindex-widget-overlay + 1;
			}
			
			.stretched-link:after {
				z-index: $zindex-widget-overlay + 2;
			}
		}
		
		.bg-full {
			min-height: 400px;
		}
	}
}
