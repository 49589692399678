.image-area-section {
	width: 100%;
	overflow: hidden;
	
	position: relative;
	
	.slick-prev, .slick-next {
		top: 55%;
		z-index: $zindex-overlay - 1;
	}
	
	.slick-next {
		right: 15px;
	}
	
	.slick-prev {
		left: 15px;
	}
	
	.slick-dots button:before {
		font-size: 2rem;
	}
	
	&.overlay {
		position: relative;
	}
	
	.image-item, .slide-item {
		min-height: 640px;
		
		@include media-breakpoint-up(sm) {
			min-height: 600px;
		}
		
		@include media-breakpoint-up(lg) {
			min-height: 680px;
		}
	}
	
	.image-item-inner {
		position: relative;
		z-index: $zindex-image-item;
		width: 100%;
	}
}
