@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	} @else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	} @else {
		@return url($slick-font-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #ffffff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	
	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;
		
		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}
	
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
	
	&:before {
		font-family: $slick-font-family;
		font-size: 20px;
		line-height: 1;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.slick-prev {
	left: -25px;
	
	[dir="rtl"] & {
		left: auto;
		right: -25px;
	}
	
	&:before {
		content: $slick-prev-character;
		
		[dir="rtl"] & {
			content: $slick-next-character;
		}
	}
}

.slick-next {
	right: -25px;
	
	[dir="rtl"] & {
		left: -25px;
		right: auto;
	}
	
	&:before {
		content: $slick-next-character;
		
		[dir="rtl"] & {
			content: $slick-prev-character;
		}
	}
}

/* Dots */
.slick-dots {
	position: absolute;
	bottom: 20px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	
	li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		
		button {
			border: 0;
			background: transparent;
			display: block;
			width: 20px;
			height: 20px;
			line-height: 0;
			font-size: 0;
			color: transparent;
			padding: 5px;
			margin-left: 2px;
			margin-right: 2px;
			cursor: pointer;
			transition: $transition-base;
			
			&:hover, &:focus {
				outline: none;
				transition: $transition-base;
				
				&:before {
					transition: $transition-base;
					opacity: $slick-opacity-on-hover;
				}
			}
			
			&:before {
				transition: $transition-base;
				position: absolute;
				top: 0;
				left: 0;
				content: $slick-dot-character;
				width: 20px;
				height: 20px;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				line-height: 20px;
				text-align: center;
				color: $slick-dot-color;
				opacity: $slick-opacity-not-active;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		
		&.slick-active button:before {
			color: $slick-dot-color-active;
			opacity: $slick-opacity-default;
		}
	}
}

//@include media-breakpoint-down(md) {
//	.slick-arrow {
//		bottom: -3.5rem;
//		top: auto;
//	}
//
//	.slick-prev {
//		left: 40%;
//	}
//
//	.slick-next {
//		right: 40%;
//	}
//}
