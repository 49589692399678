.overlay {
	position: relative;
	
	&:after {
		content: "";
		position: absolute;
		z-index: $zindex-overlay;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

@each $color, $value in $theme-colors {
	@include overlay-variant(".overlay-#{$color}", $value, .3);
}
