/* source-sans-pro-regular - latin */
@font-face {
	font-family: "Source Sans Pro";
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	src: url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-regular.eot"); /* IE9 Compat Modes */
	src: local(""),
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-regular.woff") format("woff"), /* Modern Browsers */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-regular.svg#SourceSansPro") format("svg"); /* Legacy iOS */
}

/* source-sans-pro-italic - latin */
@font-face {
	font-family: "Source Sans Pro";
	font-display: swap;
	font-style: italic;
	font-weight: 400;
	src: url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-italic.eot"); /* IE9 Compat Modes */
	src: local(""),
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-italic.woff2") format("woff2"), /* Super Modern Browsers */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-italic.woff") format("woff"), /* Modern Browsers */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-italic.ttf") format("truetype"), /* Safari, Android, iOS */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-italic.svg#SourceSansPro") format("svg"); /* Legacy iOS */
}

/* source-sans-pro-700 - latin */
@font-face {
	font-family: "Source Sans Pro";
	font-display: swap;
	font-style: normal;
	font-weight: 700;
	src: url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700.eot"); /* IE9 Compat Modes */
	src: local(""),
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700.woff2") format("woff2"), /* Super Modern Browsers */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700.woff") format("woff"), /* Modern Browsers */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700.ttf") format("truetype"), /* Safari, Android, iOS */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700.svg#SourceSansPro") format("svg"); /* Legacy iOS */
}

/* source-sans-pro-700italic - latin */
@font-face {
	font-family: "Source Sans Pro";
	font-display: swap;
	font-style: italic;
	font-weight: 700;
	src: url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700italic.eot"); /* IE9 Compat Modes */
	src: local(""),
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700italic.woff2") format("woff2"), /* Super Modern Browsers */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700italic.woff") format("woff"), /* Modern Browsers */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700italic.ttf") format("truetype"), /* Safari, Android, iOS */
	url($base-font-path + "source-sans-pro/source-sans-pro-v14-latin-700italic.svg#SourceSansPro") format("svg"); /* Legacy iOS */
}

/* heebo-100 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 100;
	src: url($base-font-path + "heebo-v26-latin/heebo-v26-latin-100.woff2") format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* heebo-200 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 200;
	src: url($base-font-path + "heebo-v26-latin/heebo-v26-latin-200.woff2") format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* heebo-300 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 300;
	src: url($base-font-path + "heebo-v26-latin/heebo-v26-latin-300.woff2") format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* heebo-regular - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 400;
	src: url($base-font-path + "heebo-v26-latin/heebo-v26-latin-regular.woff2") format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* heebo-500 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 500;
	src: url($base-font-path + "heebo-v26-latin/heebo-v26-latin-500.woff2") format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* heebo-600 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 600;
	src: url($base-font-path + "heebo-v26-latin/heebo-v26-latin-600.woff2") format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* heebo-700 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 700;
	src: url($base-font-path + "heebo-v26-latin/heebo-v26-latin-700.woff2") format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* heebo-800 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 800;
	src: url($base-font-path + "heebo-v26-latin/heebo-v26-latin-800.woff2") format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* heebo-900 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Heebo';
	font-style: normal;
	font-weight: 900;
	src: url($base-font-path + "heebo-v26-latin/heebo-v26-latin-900.woff2") format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }