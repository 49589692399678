// Variables
//
// Don't update any bootstrap files. Add your overwriting and themplate variables here.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;
$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-cssgrid: false;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

// Prefix for :root CSS variables
//
$variable-prefix: dieeisdielerei-;

// Z-Index Master List
//
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-overlay: 1030;
$zindex-image-item: 1040;
$zindex-offcanvas-backdrop: 1040;
$zindex-offcanvas: 1045;
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
$zindex-widget: 1890;
$zindex-widget-overlay: 1891;
$zindex-modal-backdrop: 1940;
$zindex-modal: 1950;
$zindex-mobilenav: 2009;
$zindex-navbar: 2010;

// Images
//
$base-img-path: "../img/";

// Transitions
//
$transition-base: all 0.3s 0.175s cubic-bezier(0.25, 0.8, 0.25, 1);
$transfrom-transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1),
	opacity 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;
$transition-collapse-width: width 0.35s ease;

// Fonts
//
$base-font-path: "../fonts/";
$generic-fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
	"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
	"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Colors
//
$primary: #e40071;
$secondary: #6c757d;
$dark: #232323;
$light: #777777;
$white: #ffffff;
$black: #000000;
$success: #28a745;
$warning: #a79a28;
$danger: #a72833;

// Component colors
$body-color: $dark;
$lead-color: $body-color;
$border-color: #f6f6f6;
$headings-color: $black;

// Theme colors
$theme-colors: ();
$theme-colors: map-merge(
	(
		"primary": $primary,
		"secondary": $secondary,
		"success": $success,
		"warning": $warning,
		"danger": $danger,
		"light": $light,
		"dark": $dark,
		"white": $white,
		"black": $black,
	),
	$theme-colors
);

// Components
//
// Define common padding and border radius sizes and more.
$border-width: 1px;
$border-widths: (
	1: 1px,
	2: 2px,
	3: 3px,
	4: 4px,
	5: 5px,
);
$border-radius: 0.25rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.3rem;
$border-radius-pill: 50rem;

$box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);

$component-active-color: $white;
$component-active-bg: $primary;

$aspect-ratios: (
	"1x1": 100%,
	"4x3": calc(3 / 4 * 100%),
	"16x9": calc(9 / 16 * 100%),
	"21x9": calc(9 / 21 * 100%),
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "Heebo", -apple-system, BlinkMacSystemFont,
	"Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: var(--#{$variable-prefix}font-sans-serif);
$font-family-code: var(--#{$variable-prefix}font-monospace);
$headings-font-family: var(--#{$variable-prefix}font-sans-serif);

$font-size-root: null;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-sizes: (
	1: $h1-font-size,
	2: $h2-font-size,
	3: $h3-font-size,
	4: $h4-font-size,
	5: $h5-font-size,
	6: $h6-font-size,
);

// Spacing
//
$spacer: 1rem;
$spacers: (
	0: 0,
	1: (
		$spacer * 1,
	),
	2: (
		$spacer * 1.5,
	),
	3: (
		$spacer * 2,
	),
	4: (
		$spacer * 3,
	),
	5: (
		$spacer * 4.5,
	),
	6: (
		$spacer * 5.8,
	),
	7: (
		$spacer * 7,
	),
);

// Slick Slider
//
$slick-font-path: "../fonts/slick/";
$slick-font-family: "dieeisdielerei Icons";
$slick-loader-path: $base-img-path;
$slick-arrow-color: $dark;
$slick-dot-color: $dark;
$slick-dot-color-active: $primary;
$slick-prev-character: "\e801";
$slick-next-character: "\e802";
$slick-dot-character: "\2022";
$slick-dot-size: 1rem;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 1;

// Navbars
//
$navbar-light-color: $primary;
$navbar-light-hover-color: $dark;
$navbar-light-active-color: $dark;
$navbar-light-disabled-color: rgba($black, 0.3);

// Links
//
$link-decoration: none;

// Lead texts
//
$lead-font-weight: 400;

// Container
//
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1190px,
	xxl: 1320px,
);

// Accordion
//
$accordion-border-width: 0;
$accordion-border-radius: 0;
$accordion-padding-y: 0;
$accordion-padding-x: 0;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $primary;
$accordion-button-color: $primary;
$accordion-icon-color: $primary;
$accordion-icon-active-color: $accordion-icon-color;
