// Import project Variables
@import "inc/variables";

// Custom functions
@import "inc/functions";

// Loader
@import "inc/loader";

// Fonts and icon-font declaration
@import "inc/fonts";
@import "inc/icons";

// Import customized Bootstrap soruce
@import "inc/bootstrap";

// Overlays
@import "inc/overlays";

// Animations
@import "inc/animations";

// Theme reset
@import "inc/reset";
@import "inc/video";

// Partials
@import "partials/header";
@import "partials/image-area";
@import "partials/page";
@import "partials/footer";

// Template parts
@import "template-parts/grid-section";

// Vendor
@import "~slick-carousel/slick/slick";
@import "vendor/featherlight";
@import "vendor/slick";
