.loading-screen, .loading-screen:before {
	position: fixed;
	top: 0;
	left: 0;
}

.loading-screen {
	z-index: 999999;
	height: 64px;
	width: 64px;
	margin: auto;
	bottom: 0;
	right: 0;
}

.loading-screen:before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	background: $white;
}
